import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
// @ts-ignore
import qs from 'qs'

import { config } from './config'
import MockAdapter from 'axios-mock-adapter'
import { useCache } from '~/common/useCache'
import { commonRefreshToken } from '~/apis/common'
import { useAppStore } from '~/stores/app'
import { sendUserInfo } from '~/plugins/message'
import { logoutAndCleanData } from '~/common/Common'
import { type ApiErrorParam } from '~/common/ErrorHandler'
import { COOKIE_EXPIRE } from '~/common/AppGlobalVar'

const {
  result_code,
  withe_result_codes,
  token_expire_code,
  token_error_code,
  token_error_reject_code,
  access_error_code,
  reject_code,
} = config

export const PATH_URL = import.meta.env.VITE_BASE_URL
const app_env = import.meta.env.VITE_ENV
if (app_env === 'LOCAL' || app_env === 'DEV') {
  config.credentials = false
  console.log('credentials ' + `${app_env} ` + config.credentials)
} else {
  config.credentials = true
  console.log('credentials ' + `${app_env} ` + config.credentials)
}

// Create an axios instance
const service: AxiosInstance = axios.create({
  baseURL: PATH_URL, // api 的 base_url
  timeout: config.request_timeout, // 请求超时时间
  withCredentials: config.credentials,
})
const serviceMock: AxiosInstance = axios.create({
  baseURL: 'http://localhost:3000', // api 的 base_url
  timeout: config.request_timeout, // 请求超时时间
  withCredentials: config.credentials,
})
export const mock = new MockAdapter(serviceMock)

//console.log(PATH_URL)
// request拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (
      config.method === 'post' &&
      (config.headers as AxiosRequestHeaders)['Content-Type'] ===
        'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(config.data)
    }

    /* if (
       config.method === 'post' &&
       config.url === '/api/assignment/assignmentHomeworkInfo' &&
       config.data.HW_TYPE_SUBMIT_CD === 'IMG'
     ) {
       config.headers['Content-Type'] = 'multipart/form-data'
     }*/

    // 인증 토큰 설정
    const wsCache = useCache()
    if (
      wsCache.get('userInfo') != null &&
      wsCache.get('userInfo').ACCESS_TOKEN != null
    ) {
      const token = wsCache.get('userInfo').ACCESS_TOKEN
      //console.log('access token---', token)
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
    }

    /* const env = import.meta.env.MODE
         if (env === 'development') {
           config.headers.Authorization =
             'Bearer ' +
             'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJFWFAiOjE2OTc1Mjc0NzEsIlVNX0ZMQUciOiIwMzAiLCJVTV9JRFgiOiIxNTgzOTciLCJVTV9JRCI6ImR5YnRlc3QiLCJJU19SRUZSRVNIIjpmYWxzZX0.HFaTQAMS08dmMLRSX0hdk-c-cMRzfUGWMhOn0hXITZk'
         }*/

    // get参数编码
    if (config.method === 'get' && config.params) {
      let url = config.url as string
      url += '?'
      const keys = Object.keys(config.params)
      for (const key of keys) {
        if (config.params[key] !== void 0 && config.params[key] !== null) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`
        }
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error: AxiosError) => {
    // Do something with request error
    console.error(error) // for debug
    Promise.reject(error)
  },
)

// response 拦截器
let isRefreshing = false
//@ts-ignore
let failedQueue = []
//@ts-ignore
const processQueue = (token = null) => {
  //@ts-ignore
  failedQueue.forEach((prom) => {
    /*if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }*/
    prom.resolve(token)
  })
  failedQueue = []
}
service.interceptors.response.use(
  // @ts-ignore
  async (response: AxiosResponse<any>) => {
    //console.log('[Request URL]:', response.config.url)
    //console.log('[Request Headers]:', response.config.headers.Authorization)
    //console.log('[Response URL]:', response.request.responseURL)
    // console.log('[Response Status]:', response.status)

    //console.log('[Response Data]:', response.data)
    if (response.config.responseType === 'blob') {
      // 如果是文件流，直接过
      return response
    } else if (response.data.code === result_code) {
      saveUserInfo()
      return response
      // refresh code 에러 발생시 api 실행.
    } else if (response.data.code === token_expire_code) {
      //토큰 만료
      // @ts-ignore
      if (response.config.url.includes('/api/common/commonRefreshToken')) {
        logoutAndCleanData()
      }
      //console.log('토큰 만료')
      const wsCache = useCache()
      if (
        wsCache.get('userInfo') != null &&
        wsCache.get('userInfo').ACCESS_TOKEN != null
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then((token) => {
              const originalRequest = response.config
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return axios(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }
        isRefreshing = true
        return await commonRefreshToken({
          REFRESH_TOKEN: wsCache.get('userInfo').REFRESH_TOKEN,
        }).then((res) => {
          if (res && res.data.code === '100') {
            //console.log('refresh token')
            //console.log(res)

            const app = useAppStore()
            //console.log(app.userInfo)

            //@ts-ignore
            app.userInfo.ACCESS_TOKEN = res.data.data.ACCESS_TOKEN
            //@ts-ignore
            app.userInfo.REFRESH_TOKEN = res.data.data.REFRESH_TOKEN

            const date = new Date()
            // 만료 시간 구하기(만료일을 ms단위로 변경)
            date.setTime(date.getTime() + COOKIE_EXPIRE)
            //date.setTime(date.getTime() + 3 * 60 * 60 * 1000)
            const cookie = useCookie('userInfo', { expires: date })
            //@ts-ignore
            cookie.value = app.userInfo

            wsCache.set('userInfo', app.userInfo)
            saveUserInfo()

            response.config.headers.Authorization =
              'Bearer ' + res.data.data.ACCESS_TOKEN
            isRefreshing = false
            if (failedQueue.length > 0) {
              // @ts-ignore
              processQueue(res.data.data.ACCESS_TOKEN)
            }
            //console.log(app.userInfo)
            return axios.request(response.config)
          } else if (response.data.code === token_error_code) {
            //토큰 오류
            isRefreshing = false
            failedQueue = []
            logoutAndCleanData()
            saveUserInfo()
          } else {
            isRefreshing = false
            failedQueue = []
            logoutAndCleanData()
            saveUserInfo()
          }
          const param: ApiErrorParam = {
            api_params: response.config.params ?? '',
            api_url: response.config.url ?? '',
            http_status: response.status,
            response: response.data,
          }
          // sendError(ERROR_TYPE.API_ERROR, param)
          return Promise.reject(response.config)
        })
      } else {
        isRefreshing = false
        failedQueue = []
        logoutAndCleanData()
        saveUserInfo()
        const param: ApiErrorParam = {
          api_params: response.config.params ?? '',
          api_url: response.config.url ?? '',
          http_status: response.status,
          response: response.data,
        }
        // sendError(ERROR_TYPE.API_ERROR, param)
        return Promise.reject(response.config)
      }
    } else if (
      response.data.code === token_error_code ||
      response.data.code === token_error_reject_code
    ) {
      //토큰 오류
      isRefreshing = false
      failedQueue = []
      logoutAndCleanData()
      saveUserInfo()
      const param: ApiErrorParam = {
        api_params: response.config.params ?? '',
        api_url: response.config.url ?? '',
        http_status: response.status,
        response: response.data,
      }
      // sendError(ERROR_TYPE.API_ERROR, param)
      return Promise.reject(response.config)
    } else if (
      withe_result_codes.filter((item) => item === response.data.code).length >
      0
    ) {
      if (response.config.url.includes('/api/login/loginTokenInfo')) {
        useRouter().push({
          path: '/reject',
          query: {
            msg: response.data.msg,
          },
        })
        return Promise.reject(response.config)
      } else {
        return response
      }
    } else if (
      response.data.code === access_error_code ||
      response.data.code === reject_code
    ) {
      const app = useAppStore()
      app.noDataPageOpen = true
      app.bottomVisible = true
      app.currentMenu = 5

      return Promise.reject(response.config)
    } else {
      //alert
      //console.error('invalid error code : ' + response.data.code)
      const param: ApiErrorParam = {
        api_params: response.config.params ?? '',
        api_url: response.config.url ?? '',
        http_status: response.status,
        response: response.data,
      }
      // sendError(ERROR_TYPE.API_ERROR, param)
      return response
    }
  },
  (error: AxiosError) => {
    console.error('err' + error) // for debug
    return Promise.reject(error)
  },
)

const saveUserInfo = () => {
  const wsCache = useCache()
  try {
    sendUserInfo(wsCache.get('userInfo'))
  } catch (e) {
    console.error(e)
  }
}

export { service, serviceMock }
