import request from '@/apis/axios'
import { IResponse } from '@/apis/axios/config'

import type {
  CommonMenuInfoParam,
  CommonMenuInfoResult,
  CommonRefreshTokenParam,
  CommonRefreshTokenResult,
  PushReadInfoParam,
  PushReadInfoResult,
  PushUseInfoParam,
  PushUseInfoResult,
} from './types'
import { mock } from '~/apis/axios/service'
import { useCache } from '~/common/useCache'
import { logoutAndCleanData } from '~/common/Common'
import { jwtDecode } from 'jwt-decode'

mock.onPost('/mock/common/commonMenuInfo').reply(200, {
  result: 'Success',
  code: '100',
  msg: '성공',
  data: {
    menu_cat1: [
      {
        MENU_SEQ: '2',
        MENU_NM: '\ud559\uc2b5 \uacfc\uc81c',
        MENU_PAGE_URL: '\/assignments',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '2',
        SITE_TYPE: 'L',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navTask',
        CAT2_INFO: [],
      },
      {
        MENU_SEQ: '1',
        MENU_NM: '\uc218\uc5c5 \uc77c\uc9c0',
        MENU_PAGE_URL: '\/daily',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '1',
        SITE_TYPE: 'L',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navDaily',
        CAT2_INFO: [],
      },
      {
        MENU_SEQ: '25',
        MENU_NM: '\uc131\uc801 \uae30\ub85d',
        MENU_PAGE_URL: '\/grade',
        MENU_SORT: '3',
        MENU_DEPTH: '1',
        MENU_CODE: '25',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navGrade',
        CAT2_INFO: [
          {
            MENU_SEQ: '26',
            MENU_NM: '\uc77c\uc77c \uc131\uc801',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '1',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/26',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeDaily',
          },
          {
            MENU_SEQ: '30',
            MENU_NM: 'Holistic',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '2',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/30',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeHolistic',
          },
          {
            MENU_SEQ: '27',
            MENU_NM: '\uc5d0\uc138\uc774',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '3',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/27',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeEssay',
          },
          {
            MENU_SEQ: '31',
            MENU_NM: 'SR \ud14c\uc2a4\ud2b8',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '4',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/31',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeSRTest',
          },
          {
            MENU_SEQ: '28',
            MENU_NM: '\ud615\uc131\ud3c9\uac00',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '5',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/28',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeFormative',
          },
          {
            MENU_SEQ: '29',
            MENU_NM: 'CDA',
            MENU_PAGE_URL: '\/grade',
            MENU_SORT: '6',
            MENU_DEPTH: '2',
            MENU_CODE: '25\/29',
            SITE_TYPE: 'T',
            TOKEN_TYPE: 'N',
            MENU_CLASS: 'GradeFormative',
          },
        ],
      },
      {
        MENU_SEQ: '17',
        MENU_NM: '\uc218\uc5c5 \uc790\ub8cc\uc2e4',
        MENU_PAGE_URL: '\/contentLibrary',
        MENU_SORT: '5',
        MENU_DEPTH: '1',
        MENU_CODE: '17',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navData',
        CAT2_INFO: [],
      },
    ],
    menu_cat2: [
      {
        MENU_SEQ: '15',
        MENU_NM: '\uc628\ub77c\uc778 \uacb0\uc81c',
        MENU_PAGE_URL: '\/payment\/edu',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '15',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navPay',
      },
      {
        MENU_SEQ: '3',
        MENU_NM: '\uc154\ud2c0\ubc84\uc2a4',
        MENU_PAGE_URL: '\/bus',
        MENU_SORT: '3',
        MENU_DEPTH: '1',
        MENU_CODE: '3',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navShuttle',
      },
      {
        MENU_SEQ: '21',
        MENU_NM: '\uacf5\uc9c0\uc0ac\ud56d',
        MENU_PAGE_URL: '\/notice',
        MENU_SORT: '4',
        MENU_DEPTH: '1',
        MENU_CODE: '21',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navNotice',
      },
    ],
    menu_other: [
      {
        MENU_SEQ: '38',
        MENU_NM: '\ud0a4\uc988\ub178\ud2b8',
        MENU_PAGE_URL: null,
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '38',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navKids',
      },
      {
        MENU_SEQ: '5',
        MENU_NM: 'Holistic \uc628\ub77c\uc778 \ud559\uc2b5',
        MENU_PAGE_URL: 'https:\/\/api.dybwindow.com\/other_contents\/holistic',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '5',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navHolistic',
      },
      {
        MENU_SEQ: '9',
        MENU_NM: 'Highlights \ub3c4\uc11c\uad00',
        MENU_PAGE_URL:
          'https:\/\/api.dybwindow.com\/other_contents\/highlights',
        MENU_SORT: '3',
        MENU_DEPTH: '1',
        MENU_CODE: '9',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navHighlights',
      },
      {
        MENU_SEQ: '18',
        MENU_NM: 'AR \ub3c5\uc11c \ud034\uc988',
        MENU_PAGE_URL: '',
        MENU_SORT: '4',
        MENU_DEPTH: '1',
        MENU_CODE: '18',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navAr',
      },
      {
        MENU_SEQ: '7',
        MENU_NM: 'ONSTP \ub3d9\uc601\uc0c1 \uac15\uc758',
        MENU_PAGE_URL: 'https:\/\/api.dybwindow.com\/other_contents\/onstp',
        MENU_SORT: '5',
        MENU_DEPTH: '1',
        MENU_CODE: '7',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navOnstp',
      },
      {
        MENU_SEQ: '8',
        MENU_NM: 'DYB Pedia \uac15\uc758',
        MENU_PAGE_URL: 'https:\/\/api.dybwindow.com\/other_contents\/pedia',
        MENU_SORT: '6',
        MENU_DEPTH: '1',
        MENU_CODE: '8',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navPedia',
      },
      {
        MENU_SEQ: '22',
        MENU_NM: 'CREO-Bot',
        MENU_PAGE_URL: 'https:\/\/api.dybwindow.com\/other_contents\/creobot',
        MENU_SORT: '7',
        MENU_DEPTH: '1',
        MENU_CODE: '22',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navBot',
      },
      {
        MENU_SEQ: '23',
        MENU_NM: 'myON \ub3c4\uc11c\uad00',
        MENU_PAGE_URL: 'https:\/\/www.myon.com\/login\/index.html',
        MENU_SORT: '8',
        MENU_DEPTH: '1',
        MENU_CODE: '23',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navMyOn',
      },
      {
        MENU_SEQ: '24',
        MENU_NM: 'News-O-Matic',
        MENU_PAGE_URL: 'https:\/\/www.naver.com',
        MENU_SORT: '9',
        MENU_DEPTH: '1',
        MENU_CODE: '24',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navNews',
      },
      {
        MENU_SEQ: '33',
        MENU_NM: 'DYB \uc785\uc2dc\uc804\ub7b5\uc5f0\uad6c\uc18c',
        MENU_PAGE_URL: 'https:\/\/api.dybwindow.com\/other_contents\/dybgenos',
        MENU_SORT: '11',
        MENU_DEPTH: '1',
        MENU_CODE: '33',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navHighlights',
      },
      {
        MENU_SEQ: '14',
        MENU_NM: "Song's \ube14\ub85c\uadf8",
        MENU_PAGE_URL:
          'https:\/\/blog.naver.com\/PostList.naver?blogId=dybchoisun',
        MENU_SORT: '12',
        MENU_DEPTH: '1',
        MENU_CODE: '14',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navSongsBlog',
      },
    ],
    menu_cat3: [
      {
        MENU_SEQ: '10',
        MENU_NM: '\ub0b4 \uc815\ubcf4',
        MENU_PAGE_URL: '\/myinfo',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '10',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navMy',
      },
      {
        MENU_SEQ: '4',
        MENU_NM: '\ub098\uc758 \ud074\ub798\uc2a4',
        MENU_PAGE_URL: '\/class',
        MENU_SORT: '3',
        MENU_DEPTH: '1',
        MENU_CODE: '4',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navClass',
      },
      {
        MENU_SEQ: '11',
        MENU_NM: '\uc571 \uc124\uc815',
        MENU_PAGE_URL: null,
        MENU_SORT: '4',
        MENU_DEPTH: '1',
        MENU_CODE: '11',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navSet',
      },
      {
        MENU_SEQ: '12',
        MENU_NM: '\ub85c\uadf8\uc544\uc6c3',
        MENU_PAGE_URL: null,
        MENU_SORT: '5',
        MENU_DEPTH: '1',
        MENU_CODE: '12',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navlogout',
      },
    ],
    menu_cat4: [],
    menu_cat5: [],
    menu_cat6: [],
    menu_bottom: [],
    SHUTTLE_TYPE: 'Y',
    shuttle_info: {
      SHUTTLE_TYPE: 'Y',
      SHUTTLE_URL: 'https:\/\/dyb.yellowbus.services\/',
      studentId: '158397',
      academyId: '001000001014',
      studentName: '\ucc28\uae38\ub3d9Ab',
    },
  },
})

/**
 * 0.4. 메뉴 조회
 * 로그인 - api/common/commonMenuInfo
 */
export const commonMenuInfo = (
  params: CommonMenuInfoParam,
): Promise<IResponse<CommonMenuInfoResult>> => {
  //params의 UM_IDX가 0이거나 '0' 이면 엑세스 토큰에서 UM_IDX를 가져와서 params에 추가
  if (params.UM_IDX === '' || params.UM_IDX === '0') {
    const wsCache = useCache()
    if (
      wsCache.get('userInfo') != null &&
      wsCache.get('userInfo').ACCESS_TOKEN != null
    ) {
      const accessToken = wsCache.get('userInfo').ACCESS_TOKEN
      if (accessToken) {
        //accessToken(jwt) 파싱 후 UM_IDX 추가
        const jwt = jwtDecode(accessToken)
        params.UM_IDX = accessToken.UM_IDX
      } else {
        //로그인 문제 발생 시 로그아웃 처리
        logoutAndCleanData()
        return Promise.reject('로그인 정보가 없습니다.')
      }
    }
  }
  return request.get({ url: '/api/common/commonMenuInfo', params })
}

/**
 * 0.2. Refresh Token 검증
 * commonRefreshToken
 */
export const commonRefreshToken = (
  params: CommonRefreshTokenParam,
): Promise<IResponse<CommonRefreshTokenResult>> => {
  return request.get({ url: '/api/common/commonRefreshToken', params })
}

/**
 * 2.5.2 알림 읽음 여부 조회
 * commonRefreshToken
 */
export const pushReadInfo = (
  params: PushReadInfoParam,
): Promise<IResponse<PushReadInfoResult>> => {
  return request.get({ url: '/api/push/pushReadInfo', params })
}

/**
 * 2.5.6. 디바이
 */
export const pushUseInfo = (
  params: PushUseInfoParam,
): Promise<IResponse<PushUseInfoResult>> => {
  return request.put({ url: '/api/push/pushUseInfo', params })
}

export const commonMenuInfoMock = (): Promise<
  IResponse<CommonMenuInfoResult>
> => {
  return request.mockPost({ url: '/mock/common/commonMenuInfo' })
}
