import { CommonMenuInfo } from '~/apis/common/types'
import { type LoginInfo, LoginUserData } from '~/apis/login/types'
import { parse, stringify } from 'zipson'
import { commonMenuInfo } from '~/apis/common'

export interface appState {
  menu: CommonMenuInfo | undefined
  userInfo: LoginUserData | null
  currentMenu: number
  appType: string
  version: string
  bottomVisible: boolean
  appSettingDialogVisible: boolean
  alarmCount: number
  menuSeq: string
  activeHidden: boolean
  studentId: string
  academyId: string
  studentName: string
  redirectPath: string
  isLayerOpen: boolean
  cameraPermission: boolean
  micPermission: boolean
  appPermissionDialog: boolean
  pushPermission: boolean
  noDataPageOpen: boolean
  loginInfo: LoginInfo
  noticePopupExpireDate: string
  isOldDevice: boolean
  nativeStore: any
}

export const useAppStore = defineStore('window', {
  state: (): appState => ({
    menu: undefined,
    userInfo: null,
    currentMenu: 2,
    appType: '7',
    version: '0.0.0',
    bottomVisible: true,
    appSettingDialogVisible: false,
    alarmCount: 0,
    menuSeq: '',
    activeHidden: false,
    academyId: '',
    studentId: '',
    studentName: '',
    redirectPath: '',
    isLayerOpen: false,
    cameraPermission: false,
    micPermission: false,
    appPermissionDialog: false,
    pushPermission: false,
    noDataPageOpen: false,
    loginInfo: { auto_login: false, id: '', pw: '' },
    noticePopupExpireDate: '',
    isOldDevice: false,
    nativeStore: {},
  }),
  actions: {
    init() {
      this.menu = undefined
      this.userInfo = null
      this.currentMenu = 2
      this.bottomVisible = true
      this.appSettingDialogVisible = false
      this.alarmCount = 0
      this.menuSeq = ''
      this.activeHidden = false
      this.academyId = ''
      this.studentId = ''
      this.studentName = ''
      this.redirectPath = ''
      this.isLayerOpen = false
      this.cameraPermission = false
      this.micPermission = false
      this.appPermissionDialog = false
      this.pushPermission = false
      this.noDataPageOpen = false
      this.isOldDevice = false
    },
    setMenu(menu: CommonMenuInfo) {
      this.menu = menu
    },
    setUserInfo(userInfo: LoginUserData) {
      this.userInfo = userInfo
    },
    setCurrentMenu(menus: [], currentMenu: number) {
      menus.forEach((item: HTMLElement, index: number) => {
        if (index === currentMenu) {
          item.classList.remove('active')
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
      this.currentMenu = currentMenu
    },
    setAppType(appType: string) {
      this.appType = appType
    },
    setVersion(version: string) {
      this.version = version
    },
    layerOpen() {
      if (this.isLayerOpen) return
      this.isLayerOpen = true
      // @ts-ignore
      document.querySelector('body').classList.add('activeHidden')
      //document.querySelector('html, body').classList.add('hiddenBody')
      const { body } = document
      const pageY = window.pageYOffset
      body.setAttribute('scrollY', pageY.toString())
      body.style.overflow = 'hidden'
      body.style.position = 'fixed'
      body.style.left = '0px'
      body.style.right = '0px'
      body.style.bottom = '0px'
      body.style.top = `-${pageY}px`
    },
    layerClose() {
      if (!this.isLayerOpen) return
      this.isLayerOpen = false
      // @ts-ignore
      document.querySelector('body').classList.remove('activeHidden')
      //document.querySelector('html, body').classList.remove('hiddenBody')
      const { body } = document
      body.style.removeProperty('overflow')
      body.style.removeProperty('position')
      body.style.removeProperty('top')
      body.style.removeProperty('left')
      body.style.removeProperty('right')
      body.style.removeProperty('bottom')
      window.scrollTo(0, Number(body.getAttribute('scrollY')))
      //body.removeAttribute('scrollY')
    },
    setNoticePopupExpireDate(expireDate: string) {
      this.noticePopupExpireDate = expireDate
    },
    async commonMenuInfoApi() {
      const umIdx = this.userInfo?.UM_IDX.toString() ?? ''
      if (umIdx === '') return
      const res = await commonMenuInfo({
        UM_IDX: umIdx,
      })

      // sort menu Data
      res.data.data.menu_cat1 = res.data.data.menu_cat1
        //.filter((item) => menuDevicefillter(item))
        .sort((a, b) => parseInt(a.MENU_SORT) - parseInt(b.MENU_SORT))
      res.data.data.menu_cat2 = res.data.data.menu_cat2
        //.filter((item) => menuDevicefillter(item))
        .sort((a, b) => parseInt(a.MENU_SORT) - parseInt(b.MENU_SORT))
      res.data.data.menu_cat3 = res.data.data.menu_cat3
        //.filter((item) => menuDevicefillter(item))
        .sort((a, b) => parseInt(a.MENU_SORT) - parseInt(b.MENU_SORT))
      res.data.data.menu_other = res.data.data.menu_other
        //.filter((item) => menuDevicefillter(item))
        .sort((a, b) => parseInt(a.MENU_SORT) - parseInt(b.MENU_SORT))
      this.setMenu(res.data.data)
    },
    setBottomVisible(visible: boolean) {
      this.bottomVisible = visible
    },
  },
  persist: {
    // CONFIG OPTIONS HERE
    key: 'window',
    storage: persistedState.sessionStorage,
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
})
if (import.meta.hot) {
  //HMR
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
