import { default as indexUdOUwLKQiZMeta } from "C:/workspace/dyb-window-nuxt/pages/alarm/index.vue?macro=true";
import { default as essayTypeRegAvjq7Ifh42Meta } from "C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/essayTypeReg.vue?macro=true";
import { default as indexdQHwsKOSppMeta } from "C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/index.vue?macro=true";
import { default as textTypeRegMNF2TAqFWIMeta } from "C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/textTypeReg.vue?macro=true";
import { default as daye5H43tFequMeta } from "C:/workspace/dyb-window-nuxt/pages/assignments/day.vue?macro=true";
import { default as image_45pickerZQRPasYwd5Meta } from "C:/workspace/dyb-window-nuxt/pages/assignments/image-picker.vue?macro=true";
import { default as indexVyCPtg8pKbMeta } from "C:/workspace/dyb-window-nuxt/pages/assignments/index.vue?macro=true";
import { default as changen4S3Ad4ZMvMeta } from "C:/workspace/dyb-window-nuxt/pages/bus/change.vue?macro=true";
import { default as indexpa0JFm3uGDMeta } from "C:/workspace/dyb-window-nuxt/pages/bus/index.vue?macro=true";
import { default as routeTZmAuDHjjtMeta } from "C:/workspace/dyb-window-nuxt/pages/bus/route.vue?macro=true";
import { default as index48K5Kd9BYtMeta } from "C:/workspace/dyb-window-nuxt/pages/class/index.vue?macro=true";
import { default as _91idx_93byShcLtsDhMeta } from "C:/workspace/dyb-window-nuxt/pages/comments/[idx].vue?macro=true";
import { default as indexrB0dcjZc8dMeta } from "C:/workspace/dyb-window-nuxt/pages/comments/index.vue?macro=true";
import { default as index71qP8b89miMeta } from "C:/workspace/dyb-window-nuxt/pages/contentLibrary/index.vue?macro=true";
import { default as searchv7j3JNIRcFMeta } from "C:/workspace/dyb-window-nuxt/pages/contentLibrary/search.vue?macro=true";
import { default as _91idx_93BFFWEqb8u8Meta } from "C:/workspace/dyb-window-nuxt/pages/daily/[idx].vue?macro=true";
import { default as indexSBwLvfOBEWMeta } from "C:/workspace/dyb-window-nuxt/pages/daily/index.vue?macro=true";
import { default as dybSnFeLnweYrMeta } from "C:/workspace/dyb-window-nuxt/pages/dyb.vue?macro=true";
import { default as detailE9723gXXX4Meta } from "C:/workspace/dyb-window-nuxt/pages/essay/detail.vue?macro=true";
import { default as essayTypeReg8ChvHuZFgGMeta } from "C:/workspace/dyb-window-nuxt/pages/essay/essayTypeReg.vue?macro=true";
import { default as indexhntNlmEkE1Meta } from "C:/workspace/dyb-window-nuxt/pages/essay/index.vue?macro=true";
import { default as resultPoO2FjKfa5Meta } from "C:/workspace/dyb-window-nuxt/pages/essay/result.vue?macro=true";
import { default as indexwFDax2WniSMeta } from "C:/workspace/dyb-window-nuxt/pages/findId/index.vue?macro=true";
import { default as indexVpvtCqJsEcMeta } from "C:/workspace/dyb-window-nuxt/pages/findPw/index.vue?macro=true";
import { default as detailPvXuA8BtodMeta } from "C:/workspace/dyb-window-nuxt/pages/formative/detail.vue?macro=true";
import { default as indexJG2RqAEsmMMeta } from "C:/workspace/dyb-window-nuxt/pages/grade/index.vue?macro=true";
import { default as indexga5JeuengHMeta } from "C:/workspace/dyb-window-nuxt/pages/index.vue?macro=true";
import { default as assignmentfNDdgS2utVMeta } from "C:/workspace/dyb-window-nuxt/pages/learning/assignment.vue?macro=true";
import { default as reportwn3XuvcmypMeta } from "C:/workspace/dyb-window-nuxt/pages/learning/report.vue?macro=true";
import { default as indexCGmN9WstzCMeta } from "C:/workspace/dyb-window-nuxt/pages/login/index.vue?macro=true";
import { default as index2adIpVfQxtMeta } from "C:/workspace/dyb-window-nuxt/pages/main/index.vue?macro=true";
import { default as index24dLGBHvygkMeta } from "C:/workspace/dyb-window-nuxt/pages/main/index2.vue?macro=true";
import { default as indexPp1cwz4rT0Meta } from "C:/workspace/dyb-window-nuxt/pages/media/index.vue?macro=true";
import { default as indexPr9RLR2kwJMeta } from "C:/workspace/dyb-window-nuxt/pages/menu/index.vue?macro=true";
import { default as index24q2nzwXTGYMeta } from "C:/workspace/dyb-window-nuxt/pages/menu/index2.vue?macro=true";
import { default as indexsz6hShEiedMeta } from "C:/workspace/dyb-window-nuxt/pages/mockTest/index.vue?macro=true";
import { default as changePwm3chIefUowMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/changePw.vue?macro=true";
import { default as findPwWs8tOnmi1gMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/findPw.vue?macro=true";
import { default as indexrMFaugYgOGMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/index.vue?macro=true";
import { default as parentsInfoUq3mp2wHIzMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/parentsInfo.vue?macro=true";
import { default as indexPhkWypjd35Meta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/index.vue?macro=true";
import { default as searchAddressenXRk3rZLZMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/searchAddress.vue?macro=true";
import { default as searchSchoolgb5TEGyQedMeta } from "C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/searchSchool.vue?macro=true";
import { default as _91idx_93SHwA11pHy5Meta } from "C:/workspace/dyb-window-nuxt/pages/notice/[idx].vue?macro=true";
import { default as detail4ie9Qy2hmXMeta } from "C:/workspace/dyb-window-nuxt/pages/notice/detail.vue?macro=true";
import { default as indexIRhHRZljskMeta } from "C:/workspace/dyb-window-nuxt/pages/notice/index.vue?macro=true";
import { default as _91key_93AEU2d1xM4dMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/book/[key].vue?macro=true";
import { default as indexHecxWtXaYFMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/book/index.vue?macro=true";
import { default as _91key_93rXWrBdpcEMMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/edu/[key].vue?macro=true";
import { default as indexpzmJvvOzipMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/edu/index.vue?macro=true";
import { default as error3YsAYA9lfgMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/error.vue?macro=true";
import { default as failSA7hppaqWnMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/fail.vue?macro=true";
import { default as indexpUgc6lnZysMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/history/index.vue?macro=true";
import { default as indexw2r1QRTz4SMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/index.vue?macro=true";
import { default as privacy8rcdQgfBfsMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/privacy.vue?macro=true";
import { default as process66yW2u0awhMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/process.vue?macro=true";
import { default as successKnB8Ly8i4aMeta } from "C:/workspace/dyb-window-nuxt/pages/payment/success.vue?macro=true";
import { default as tokenXaZ95oBVMWMeta } from "C:/workspace/dyb-window-nuxt/pages/redirect/token.vue?macro=true";
import { default as indexQeKwwNdhXRMeta } from "C:/workspace/dyb-window-nuxt/pages/reject/index.vue?macro=true";
import { default as index6efH6pHjTrMeta } from "C:/workspace/dyb-window-nuxt/pages/setting/index.vue?macro=true";
import { default as privacyq99fQeFmveMeta } from "C:/workspace/dyb-window-nuxt/pages/setting/privacy.vue?macro=true";
import { default as termBF8KpSgTKNMeta } from "C:/workspace/dyb-window-nuxt/pages/setting/term.vue?macro=true";
import { default as _010SuQZnSL5gMeta } from "C:/workspace/dyb-window-nuxt/pages/test/01.vue?macro=true";
import { default as audiomc7okTirolMeta } from "C:/workspace/dyb-window-nuxt/pages/test/audio.vue?macro=true";
import { default as bottomoH8qNxKpPdMeta } from "C:/workspace/dyb-window-nuxt/pages/test/bottom.vue?macro=true";
import { default as bottom21jlGZsdSizMeta } from "C:/workspace/dyb-window-nuxt/pages/test/bottom2.vue?macro=true";
import { default as bottom3MkCFNUJ29tMeta } from "C:/workspace/dyb-window-nuxt/pages/test/bottom3.vue?macro=true";
import { default as children_select_01RyJlJYtOclMeta } from "C:/workspace/dyb-window-nuxt/pages/test/children_select_01.vue?macro=true";
import { default as children_select_02wmhx1DoULoMeta } from "C:/workspace/dyb-window-nuxt/pages/test/children_select_02.vue?macro=true";
import { default as children_selectYv0LGYIcI6Meta } from "C:/workspace/dyb-window-nuxt/pages/test/children_select.vue?macro=true";
import { default as circle_45chart9Zvx5ve91WMeta } from "C:/workspace/dyb-window-nuxt/pages/test/circle-chart.vue?macro=true";
import { default as datepickerzz13s7uwUXMeta } from "C:/workspace/dyb-window-nuxt/pages/test/datepicker.vue?macro=true";
import { default as deviceR5GHfS8fJ6Meta } from "C:/workspace/dyb-window-nuxt/pages/test/device.vue?macro=true";
import { default as edit5RfMdk4lzIMeta } from "C:/workspace/dyb-window-nuxt/pages/test/edit.vue?macro=true";
import { default as iframegotKGz2n1EMeta } from "C:/workspace/dyb-window-nuxt/pages/test/iframe.vue?macro=true";
import { default as image_45picker5zbZnca900Meta } from "C:/workspace/dyb-window-nuxt/pages/test/image-picker.vue?macro=true";
import { default as indexlfCWEqiMbnMeta } from "C:/workspace/dyb-window-nuxt/pages/test/index.vue?macro=true";
import { default as orientationluWY2gOUw0Meta } from "C:/workspace/dyb-window-nuxt/pages/test/orientation.vue?macro=true";
import { default as photo_select_01O1BprKbkopMeta } from "C:/workspace/dyb-window-nuxt/pages/test/photo_select_01.vue?macro=true";
import { default as photo_select_02cWDjblVPhVMeta } from "C:/workspace/dyb-window-nuxt/pages/test/photo_select_02.vue?macro=true";
import { default as photoATXQxYzO5wMeta } from "C:/workspace/dyb-window-nuxt/pages/test/photo.vue?macro=true";
import { default as photo2icfKOkL0NlMeta } from "C:/workspace/dyb-window-nuxt/pages/test/photo2.vue?macro=true";
import { default as textJSTTnxDsdvMeta } from "C:/workspace/dyb-window-nuxt/pages/test/text.vue?macro=true";
import { default as tossN3G7o8AEY2Meta } from "C:/workspace/dyb-window-nuxt/pages/test/toss.vue?macro=true";
export default [
  {
    name: indexUdOUwLKQiZMeta?.name ?? "alarm",
    path: indexUdOUwLKQiZMeta?.path ?? "/alarm",
    meta: indexUdOUwLKQiZMeta || {},
    alias: indexUdOUwLKQiZMeta?.alias || [],
    redirect: indexUdOUwLKQiZMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/alarm/index.vue").then(m => m.default || m)
  },
  {
    name: essayTypeRegAvjq7Ifh42Meta?.name ?? "assignments-idx-essayTypeReg",
    path: essayTypeRegAvjq7Ifh42Meta?.path ?? "/assignments/:idx()/essayTypeReg",
    meta: essayTypeRegAvjq7Ifh42Meta || {},
    alias: essayTypeRegAvjq7Ifh42Meta?.alias || [],
    redirect: essayTypeRegAvjq7Ifh42Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/essayTypeReg.vue").then(m => m.default || m)
  },
  {
    name: indexdQHwsKOSppMeta?.name ?? "assignments-idx",
    path: indexdQHwsKOSppMeta?.path ?? "/assignments/:idx()",
    meta: indexdQHwsKOSppMeta || {},
    alias: indexdQHwsKOSppMeta?.alias || [],
    redirect: indexdQHwsKOSppMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/index.vue").then(m => m.default || m)
  },
  {
    name: textTypeRegMNF2TAqFWIMeta?.name ?? "assignments-idx-textTypeReg",
    path: textTypeRegMNF2TAqFWIMeta?.path ?? "/assignments/:idx()/textTypeReg",
    meta: textTypeRegMNF2TAqFWIMeta || {},
    alias: textTypeRegMNF2TAqFWIMeta?.alias || [],
    redirect: textTypeRegMNF2TAqFWIMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/[idx]/textTypeReg.vue").then(m => m.default || m)
  },
  {
    name: daye5H43tFequMeta?.name ?? "assignments-day",
    path: daye5H43tFequMeta?.path ?? "/assignments/day",
    meta: daye5H43tFequMeta || {},
    alias: daye5H43tFequMeta?.alias || [],
    redirect: daye5H43tFequMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/day.vue").then(m => m.default || m)
  },
  {
    name: image_45pickerZQRPasYwd5Meta?.name ?? "assignments-image-picker",
    path: image_45pickerZQRPasYwd5Meta?.path ?? "/assignments/image-picker",
    meta: image_45pickerZQRPasYwd5Meta || {},
    alias: image_45pickerZQRPasYwd5Meta?.alias || [],
    redirect: image_45pickerZQRPasYwd5Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/image-picker.vue").then(m => m.default || m)
  },
  {
    name: indexVyCPtg8pKbMeta?.name ?? "assignments",
    path: indexVyCPtg8pKbMeta?.path ?? "/assignments",
    meta: indexVyCPtg8pKbMeta || {},
    alias: indexVyCPtg8pKbMeta?.alias || [],
    redirect: indexVyCPtg8pKbMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/assignments/index.vue").then(m => m.default || m)
  },
  {
    name: changen4S3Ad4ZMvMeta?.name ?? "bus-change",
    path: changen4S3Ad4ZMvMeta?.path ?? "/bus/change",
    meta: changen4S3Ad4ZMvMeta || {},
    alias: changen4S3Ad4ZMvMeta?.alias || [],
    redirect: changen4S3Ad4ZMvMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/bus/change.vue").then(m => m.default || m)
  },
  {
    name: indexpa0JFm3uGDMeta?.name ?? "bus",
    path: indexpa0JFm3uGDMeta?.path ?? "/bus",
    meta: indexpa0JFm3uGDMeta || {},
    alias: indexpa0JFm3uGDMeta?.alias || [],
    redirect: indexpa0JFm3uGDMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/bus/index.vue").then(m => m.default || m)
  },
  {
    name: routeTZmAuDHjjtMeta?.name ?? "bus-route",
    path: routeTZmAuDHjjtMeta?.path ?? "/bus/route",
    meta: routeTZmAuDHjjtMeta || {},
    alias: routeTZmAuDHjjtMeta?.alias || [],
    redirect: routeTZmAuDHjjtMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/bus/route.vue").then(m => m.default || m)
  },
  {
    name: index48K5Kd9BYtMeta?.name ?? "class",
    path: index48K5Kd9BYtMeta?.path ?? "/class",
    meta: index48K5Kd9BYtMeta || {},
    alias: index48K5Kd9BYtMeta?.alias || [],
    redirect: index48K5Kd9BYtMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/class/index.vue").then(m => m.default || m)
  },
  {
    name: _91idx_93byShcLtsDhMeta?.name ?? "comments-idx",
    path: _91idx_93byShcLtsDhMeta?.path ?? "/comments/:idx()",
    meta: _91idx_93byShcLtsDhMeta || {},
    alias: _91idx_93byShcLtsDhMeta?.alias || [],
    redirect: _91idx_93byShcLtsDhMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/comments/[idx].vue").then(m => m.default || m)
  },
  {
    name: indexrB0dcjZc8dMeta?.name ?? "comments",
    path: indexrB0dcjZc8dMeta?.path ?? "/comments",
    meta: indexrB0dcjZc8dMeta || {},
    alias: indexrB0dcjZc8dMeta?.alias || [],
    redirect: indexrB0dcjZc8dMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/comments/index.vue").then(m => m.default || m)
  },
  {
    name: index71qP8b89miMeta?.name ?? "contentLibrary",
    path: index71qP8b89miMeta?.path ?? "/contentLibrary",
    meta: index71qP8b89miMeta || {},
    alias: index71qP8b89miMeta?.alias || [],
    redirect: index71qP8b89miMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/contentLibrary/index.vue").then(m => m.default || m)
  },
  {
    name: searchv7j3JNIRcFMeta?.name ?? "contentLibrary-search",
    path: searchv7j3JNIRcFMeta?.path ?? "/contentLibrary/search",
    meta: searchv7j3JNIRcFMeta || {},
    alias: searchv7j3JNIRcFMeta?.alias || [],
    redirect: searchv7j3JNIRcFMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/contentLibrary/search.vue").then(m => m.default || m)
  },
  {
    name: _91idx_93BFFWEqb8u8Meta?.name ?? "daily-idx",
    path: _91idx_93BFFWEqb8u8Meta?.path ?? "/daily/:idx()",
    meta: _91idx_93BFFWEqb8u8Meta || {},
    alias: _91idx_93BFFWEqb8u8Meta?.alias || [],
    redirect: _91idx_93BFFWEqb8u8Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/daily/[idx].vue").then(m => m.default || m)
  },
  {
    name: indexSBwLvfOBEWMeta?.name ?? "daily",
    path: indexSBwLvfOBEWMeta?.path ?? "/daily",
    meta: indexSBwLvfOBEWMeta || {},
    alias: indexSBwLvfOBEWMeta?.alias || [],
    redirect: indexSBwLvfOBEWMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/daily/index.vue").then(m => m.default || m)
  },
  {
    name: dybSnFeLnweYrMeta?.name ?? "dyb",
    path: dybSnFeLnweYrMeta?.path ?? "/dyb",
    meta: dybSnFeLnweYrMeta || {},
    alias: dybSnFeLnweYrMeta?.alias || [],
    redirect: dybSnFeLnweYrMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/dyb.vue").then(m => m.default || m)
  },
  {
    name: detailE9723gXXX4Meta?.name ?? "essay-detail",
    path: detailE9723gXXX4Meta?.path ?? "/essay/detail",
    meta: detailE9723gXXX4Meta || {},
    alias: detailE9723gXXX4Meta?.alias || [],
    redirect: detailE9723gXXX4Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/essay/detail.vue").then(m => m.default || m)
  },
  {
    name: essayTypeReg8ChvHuZFgGMeta?.name ?? "essay-essayTypeReg",
    path: essayTypeReg8ChvHuZFgGMeta?.path ?? "/essay/essayTypeReg",
    meta: essayTypeReg8ChvHuZFgGMeta || {},
    alias: essayTypeReg8ChvHuZFgGMeta?.alias || [],
    redirect: essayTypeReg8ChvHuZFgGMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/essay/essayTypeReg.vue").then(m => m.default || m)
  },
  {
    name: indexhntNlmEkE1Meta?.name ?? "essay",
    path: indexhntNlmEkE1Meta?.path ?? "/essay",
    meta: indexhntNlmEkE1Meta || {},
    alias: indexhntNlmEkE1Meta?.alias || [],
    redirect: indexhntNlmEkE1Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/essay/index.vue").then(m => m.default || m)
  },
  {
    name: resultPoO2FjKfa5Meta?.name ?? "essay-result",
    path: resultPoO2FjKfa5Meta?.path ?? "/essay/result",
    meta: resultPoO2FjKfa5Meta || {},
    alias: resultPoO2FjKfa5Meta?.alias || [],
    redirect: resultPoO2FjKfa5Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/essay/result.vue").then(m => m.default || m)
  },
  {
    name: indexwFDax2WniSMeta?.name ?? "findId",
    path: indexwFDax2WniSMeta?.path ?? "/findId",
    meta: indexwFDax2WniSMeta || {},
    alias: indexwFDax2WniSMeta?.alias || [],
    redirect: indexwFDax2WniSMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/findId/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpvtCqJsEcMeta?.name ?? "findPw",
    path: indexVpvtCqJsEcMeta?.path ?? "/findPw",
    meta: indexVpvtCqJsEcMeta || {},
    alias: indexVpvtCqJsEcMeta?.alias || [],
    redirect: indexVpvtCqJsEcMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/findPw/index.vue").then(m => m.default || m)
  },
  {
    name: detailPvXuA8BtodMeta?.name ?? "formative-detail",
    path: detailPvXuA8BtodMeta?.path ?? "/formative/detail",
    meta: detailPvXuA8BtodMeta || {},
    alias: detailPvXuA8BtodMeta?.alias || [],
    redirect: detailPvXuA8BtodMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/formative/detail.vue").then(m => m.default || m)
  },
  {
    name: indexJG2RqAEsmMMeta?.name ?? "grade",
    path: indexJG2RqAEsmMMeta?.path ?? "/grade",
    meta: indexJG2RqAEsmMMeta || {},
    alias: indexJG2RqAEsmMMeta?.alias || [],
    redirect: indexJG2RqAEsmMMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/grade/index.vue").then(m => m.default || m)
  },
  {
    name: indexga5JeuengHMeta?.name ?? "index",
    path: indexga5JeuengHMeta?.path ?? "/",
    meta: indexga5JeuengHMeta || {},
    alias: indexga5JeuengHMeta?.alias || [],
    redirect: indexga5JeuengHMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: assignmentfNDdgS2utVMeta?.name ?? "learning-assignment",
    path: assignmentfNDdgS2utVMeta?.path ?? "/learning/assignment",
    meta: assignmentfNDdgS2utVMeta || {},
    alias: assignmentfNDdgS2utVMeta?.alias || [],
    redirect: assignmentfNDdgS2utVMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/learning/assignment.vue").then(m => m.default || m)
  },
  {
    name: reportwn3XuvcmypMeta?.name ?? "learning-report",
    path: reportwn3XuvcmypMeta?.path ?? "/learning/report",
    meta: reportwn3XuvcmypMeta || {},
    alias: reportwn3XuvcmypMeta?.alias || [],
    redirect: reportwn3XuvcmypMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/learning/report.vue").then(m => m.default || m)
  },
  {
    name: indexCGmN9WstzCMeta?.name ?? "login",
    path: indexCGmN9WstzCMeta?.path ?? "/login",
    meta: indexCGmN9WstzCMeta || {},
    alias: indexCGmN9WstzCMeta?.alias || [],
    redirect: indexCGmN9WstzCMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index2adIpVfQxtMeta?.name ?? "main",
    path: index2adIpVfQxtMeta?.path ?? "/main",
    meta: index2adIpVfQxtMeta || {},
    alias: index2adIpVfQxtMeta?.alias || [],
    redirect: index2adIpVfQxtMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/main/index.vue").then(m => m.default || m)
  },
  {
    name: index24dLGBHvygkMeta?.name ?? "main-index2",
    path: index24dLGBHvygkMeta?.path ?? "/main/index2",
    meta: index24dLGBHvygkMeta || {},
    alias: index24dLGBHvygkMeta?.alias || [],
    redirect: index24dLGBHvygkMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/main/index2.vue").then(m => m.default || m)
  },
  {
    name: indexPp1cwz4rT0Meta?.name ?? "media",
    path: indexPp1cwz4rT0Meta?.path ?? "/media",
    meta: indexPp1cwz4rT0Meta || {},
    alias: indexPp1cwz4rT0Meta?.alias || [],
    redirect: indexPp1cwz4rT0Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexPr9RLR2kwJMeta?.name ?? "menu",
    path: indexPr9RLR2kwJMeta?.path ?? "/menu",
    meta: indexPr9RLR2kwJMeta || {},
    alias: indexPr9RLR2kwJMeta?.alias || [],
    redirect: indexPr9RLR2kwJMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: index24q2nzwXTGYMeta?.name ?? "menu-index2",
    path: index24q2nzwXTGYMeta?.path ?? "/menu/index2",
    meta: index24q2nzwXTGYMeta || {},
    alias: index24q2nzwXTGYMeta?.alias || [],
    redirect: index24q2nzwXTGYMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/menu/index2.vue").then(m => m.default || m)
  },
  {
    name: indexsz6hShEiedMeta?.name ?? "mockTest",
    path: indexsz6hShEiedMeta?.path ?? "/mockTest",
    meta: indexsz6hShEiedMeta || {},
    alias: indexsz6hShEiedMeta?.alias || [],
    redirect: indexsz6hShEiedMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/mockTest/index.vue").then(m => m.default || m)
  },
  {
    name: changePwm3chIefUowMeta?.name ?? "myinfo-changePw",
    path: changePwm3chIefUowMeta?.path ?? "/myinfo/changePw",
    meta: changePwm3chIefUowMeta || {},
    alias: changePwm3chIefUowMeta?.alias || [],
    redirect: changePwm3chIefUowMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/changePw.vue").then(m => m.default || m)
  },
  {
    name: findPwWs8tOnmi1gMeta?.name ?? "myinfo-findPw",
    path: findPwWs8tOnmi1gMeta?.path ?? "/myinfo/findPw",
    meta: findPwWs8tOnmi1gMeta || {},
    alias: findPwWs8tOnmi1gMeta?.alias || [],
    redirect: findPwWs8tOnmi1gMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/findPw.vue").then(m => m.default || m)
  },
  {
    name: indexrMFaugYgOGMeta?.name ?? "myinfo",
    path: indexrMFaugYgOGMeta?.path ?? "/myinfo",
    meta: indexrMFaugYgOGMeta || {},
    alias: indexrMFaugYgOGMeta?.alias || [],
    redirect: indexrMFaugYgOGMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/index.vue").then(m => m.default || m)
  },
  {
    name: parentsInfoUq3mp2wHIzMeta?.name ?? "myinfo-parentsInfo",
    path: parentsInfoUq3mp2wHIzMeta?.path ?? "/myinfo/parentsInfo",
    meta: parentsInfoUq3mp2wHIzMeta || {},
    alias: parentsInfoUq3mp2wHIzMeta?.alias || [],
    redirect: parentsInfoUq3mp2wHIzMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/parentsInfo.vue").then(m => m.default || m)
  },
  {
    name: indexPhkWypjd35Meta?.name ?? "myinfo-userInfo",
    path: indexPhkWypjd35Meta?.path ?? "/myinfo/userInfo",
    meta: indexPhkWypjd35Meta || {},
    alias: indexPhkWypjd35Meta?.alias || [],
    redirect: indexPhkWypjd35Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/index.vue").then(m => m.default || m)
  },
  {
    name: searchAddressenXRk3rZLZMeta?.name ?? "myinfo-userInfo-searchAddress",
    path: searchAddressenXRk3rZLZMeta?.path ?? "/myinfo/userInfo/searchAddress",
    meta: searchAddressenXRk3rZLZMeta || {},
    alias: searchAddressenXRk3rZLZMeta?.alias || [],
    redirect: searchAddressenXRk3rZLZMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/searchAddress.vue").then(m => m.default || m)
  },
  {
    name: searchSchoolgb5TEGyQedMeta?.name ?? "myinfo-userInfo-searchSchool",
    path: searchSchoolgb5TEGyQedMeta?.path ?? "/myinfo/userInfo/searchSchool",
    meta: searchSchoolgb5TEGyQedMeta || {},
    alias: searchSchoolgb5TEGyQedMeta?.alias || [],
    redirect: searchSchoolgb5TEGyQedMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/myinfo/userInfo/searchSchool.vue").then(m => m.default || m)
  },
  {
    name: _91idx_93SHwA11pHy5Meta?.name ?? "notice-idx",
    path: _91idx_93SHwA11pHy5Meta?.path ?? "/notice/:idx()",
    meta: _91idx_93SHwA11pHy5Meta || {},
    alias: _91idx_93SHwA11pHy5Meta?.alias || [],
    redirect: _91idx_93SHwA11pHy5Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/notice/[idx].vue").then(m => m.default || m)
  },
  {
    name: detail4ie9Qy2hmXMeta?.name ?? "notice-detail",
    path: detail4ie9Qy2hmXMeta?.path ?? "/notice/detail",
    meta: detail4ie9Qy2hmXMeta || {},
    alias: detail4ie9Qy2hmXMeta?.alias || [],
    redirect: detail4ie9Qy2hmXMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: indexIRhHRZljskMeta?.name ?? "notice",
    path: indexIRhHRZljskMeta?.path ?? "/notice",
    meta: indexIRhHRZljskMeta || {},
    alias: indexIRhHRZljskMeta?.alias || [],
    redirect: indexIRhHRZljskMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93AEU2d1xM4dMeta?.name ?? "payment-book-key",
    path: _91key_93AEU2d1xM4dMeta?.path ?? "/payment/book/:key()",
    meta: _91key_93AEU2d1xM4dMeta || {},
    alias: _91key_93AEU2d1xM4dMeta?.alias || [],
    redirect: _91key_93AEU2d1xM4dMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/book/[key].vue").then(m => m.default || m)
  },
  {
    name: indexHecxWtXaYFMeta?.name ?? "payment-book",
    path: indexHecxWtXaYFMeta?.path ?? "/payment/book",
    meta: indexHecxWtXaYFMeta || {},
    alias: indexHecxWtXaYFMeta?.alias || [],
    redirect: indexHecxWtXaYFMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/book/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93rXWrBdpcEMMeta?.name ?? "payment-edu-key",
    path: _91key_93rXWrBdpcEMMeta?.path ?? "/payment/edu/:key()",
    meta: _91key_93rXWrBdpcEMMeta || {},
    alias: _91key_93rXWrBdpcEMMeta?.alias || [],
    redirect: _91key_93rXWrBdpcEMMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/edu/[key].vue").then(m => m.default || m)
  },
  {
    name: indexpzmJvvOzipMeta?.name ?? "payment-edu",
    path: indexpzmJvvOzipMeta?.path ?? "/payment/edu",
    meta: indexpzmJvvOzipMeta || {},
    alias: indexpzmJvvOzipMeta?.alias || [],
    redirect: indexpzmJvvOzipMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/edu/index.vue").then(m => m.default || m)
  },
  {
    name: error3YsAYA9lfgMeta?.name ?? "payment-error",
    path: error3YsAYA9lfgMeta?.path ?? "/payment/error",
    meta: error3YsAYA9lfgMeta || {},
    alias: error3YsAYA9lfgMeta?.alias || [],
    redirect: error3YsAYA9lfgMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/error.vue").then(m => m.default || m)
  },
  {
    name: failSA7hppaqWnMeta?.name ?? "payment-fail",
    path: failSA7hppaqWnMeta?.path ?? "/payment/fail",
    meta: failSA7hppaqWnMeta || {},
    alias: failSA7hppaqWnMeta?.alias || [],
    redirect: failSA7hppaqWnMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexpUgc6lnZysMeta?.name ?? "payment-history",
    path: indexpUgc6lnZysMeta?.path ?? "/payment/history",
    meta: indexpUgc6lnZysMeta || {},
    alias: indexpUgc6lnZysMeta?.alias || [],
    redirect: indexpUgc6lnZysMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2r1QRTz4SMeta?.name ?? "payment",
    path: indexw2r1QRTz4SMeta?.path ?? "/payment",
    meta: indexw2r1QRTz4SMeta || {},
    alias: indexw2r1QRTz4SMeta?.alias || [],
    redirect: indexw2r1QRTz4SMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: privacy8rcdQgfBfsMeta?.name ?? "payment-privacy",
    path: privacy8rcdQgfBfsMeta?.path ?? "/payment/privacy",
    meta: privacy8rcdQgfBfsMeta || {},
    alias: privacy8rcdQgfBfsMeta?.alias || [],
    redirect: privacy8rcdQgfBfsMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/privacy.vue").then(m => m.default || m)
  },
  {
    name: process66yW2u0awhMeta?.name ?? "payment-process",
    path: process66yW2u0awhMeta?.path ?? "/payment/process",
    meta: process66yW2u0awhMeta || {},
    alias: process66yW2u0awhMeta?.alias || [],
    redirect: process66yW2u0awhMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/process.vue").then(m => m.default || m)
  },
  {
    name: successKnB8Ly8i4aMeta?.name ?? "payment-success",
    path: successKnB8Ly8i4aMeta?.path ?? "/payment/success",
    meta: successKnB8Ly8i4aMeta || {},
    alias: successKnB8Ly8i4aMeta?.alias || [],
    redirect: successKnB8Ly8i4aMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: tokenXaZ95oBVMWMeta?.name ?? "redirect-token",
    path: tokenXaZ95oBVMWMeta?.path ?? "/redirect/token",
    meta: tokenXaZ95oBVMWMeta || {},
    alias: tokenXaZ95oBVMWMeta?.alias || [],
    redirect: tokenXaZ95oBVMWMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/redirect/token.vue").then(m => m.default || m)
  },
  {
    name: indexQeKwwNdhXRMeta?.name ?? "reject",
    path: indexQeKwwNdhXRMeta?.path ?? "/reject",
    meta: indexQeKwwNdhXRMeta || {},
    alias: indexQeKwwNdhXRMeta?.alias || [],
    redirect: indexQeKwwNdhXRMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/reject/index.vue").then(m => m.default || m)
  },
  {
    name: index6efH6pHjTrMeta?.name ?? "setting",
    path: index6efH6pHjTrMeta?.path ?? "/setting",
    meta: index6efH6pHjTrMeta || {},
    alias: index6efH6pHjTrMeta?.alias || [],
    redirect: index6efH6pHjTrMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: privacyq99fQeFmveMeta?.name ?? "setting-privacy",
    path: privacyq99fQeFmveMeta?.path ?? "/setting/privacy",
    meta: privacyq99fQeFmveMeta || {},
    alias: privacyq99fQeFmveMeta?.alias || [],
    redirect: privacyq99fQeFmveMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/setting/privacy.vue").then(m => m.default || m)
  },
  {
    name: termBF8KpSgTKNMeta?.name ?? "setting-term",
    path: termBF8KpSgTKNMeta?.path ?? "/setting/term",
    meta: termBF8KpSgTKNMeta || {},
    alias: termBF8KpSgTKNMeta?.alias || [],
    redirect: termBF8KpSgTKNMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/setting/term.vue").then(m => m.default || m)
  },
  {
    name: _010SuQZnSL5gMeta?.name ?? "test-01",
    path: _010SuQZnSL5gMeta?.path ?? "/test/01",
    meta: _010SuQZnSL5gMeta || {},
    alias: _010SuQZnSL5gMeta?.alias || [],
    redirect: _010SuQZnSL5gMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/01.vue").then(m => m.default || m)
  },
  {
    name: audiomc7okTirolMeta?.name ?? "test-audio",
    path: audiomc7okTirolMeta?.path ?? "/test/audio",
    meta: audiomc7okTirolMeta || {},
    alias: audiomc7okTirolMeta?.alias || [],
    redirect: audiomc7okTirolMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/audio.vue").then(m => m.default || m)
  },
  {
    name: bottomoH8qNxKpPdMeta?.name ?? "test-bottom",
    path: bottomoH8qNxKpPdMeta?.path ?? "/test/bottom",
    meta: bottomoH8qNxKpPdMeta || {},
    alias: bottomoH8qNxKpPdMeta?.alias || [],
    redirect: bottomoH8qNxKpPdMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/bottom.vue").then(m => m.default || m)
  },
  {
    name: bottom21jlGZsdSizMeta?.name ?? "test-bottom2",
    path: bottom21jlGZsdSizMeta?.path ?? "/test/bottom2",
    meta: bottom21jlGZsdSizMeta || {},
    alias: bottom21jlGZsdSizMeta?.alias || [],
    redirect: bottom21jlGZsdSizMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/bottom2.vue").then(m => m.default || m)
  },
  {
    name: bottom3MkCFNUJ29tMeta?.name ?? "test-bottom3",
    path: bottom3MkCFNUJ29tMeta?.path ?? "/test/bottom3",
    meta: bottom3MkCFNUJ29tMeta || {},
    alias: bottom3MkCFNUJ29tMeta?.alias || [],
    redirect: bottom3MkCFNUJ29tMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/bottom3.vue").then(m => m.default || m)
  },
  {
    name: children_select_01RyJlJYtOclMeta?.name ?? "test-children_select_01",
    path: children_select_01RyJlJYtOclMeta?.path ?? "/test/children_select_01",
    meta: children_select_01RyJlJYtOclMeta || {},
    alias: children_select_01RyJlJYtOclMeta?.alias || [],
    redirect: children_select_01RyJlJYtOclMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/children_select_01.vue").then(m => m.default || m)
  },
  {
    name: children_select_02wmhx1DoULoMeta?.name ?? "test-children_select_02",
    path: children_select_02wmhx1DoULoMeta?.path ?? "/test/children_select_02",
    meta: children_select_02wmhx1DoULoMeta || {},
    alias: children_select_02wmhx1DoULoMeta?.alias || [],
    redirect: children_select_02wmhx1DoULoMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/children_select_02.vue").then(m => m.default || m)
  },
  {
    name: children_selectYv0LGYIcI6Meta?.name ?? "test-children_select",
    path: children_selectYv0LGYIcI6Meta?.path ?? "/test/children_select",
    meta: children_selectYv0LGYIcI6Meta || {},
    alias: children_selectYv0LGYIcI6Meta?.alias || [],
    redirect: children_selectYv0LGYIcI6Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/children_select.vue").then(m => m.default || m)
  },
  {
    name: circle_45chart9Zvx5ve91WMeta?.name ?? "test-circle-chart",
    path: circle_45chart9Zvx5ve91WMeta?.path ?? "/test/circle-chart",
    meta: circle_45chart9Zvx5ve91WMeta || {},
    alias: circle_45chart9Zvx5ve91WMeta?.alias || [],
    redirect: circle_45chart9Zvx5ve91WMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/circle-chart.vue").then(m => m.default || m)
  },
  {
    name: datepickerzz13s7uwUXMeta?.name ?? "test-datepicker",
    path: datepickerzz13s7uwUXMeta?.path ?? "/test/datepicker",
    meta: datepickerzz13s7uwUXMeta || {},
    alias: datepickerzz13s7uwUXMeta?.alias || [],
    redirect: datepickerzz13s7uwUXMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/datepicker.vue").then(m => m.default || m)
  },
  {
    name: deviceR5GHfS8fJ6Meta?.name ?? "test-device",
    path: deviceR5GHfS8fJ6Meta?.path ?? "/test/device",
    meta: deviceR5GHfS8fJ6Meta || {},
    alias: deviceR5GHfS8fJ6Meta?.alias || [],
    redirect: deviceR5GHfS8fJ6Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/device.vue").then(m => m.default || m)
  },
  {
    name: edit5RfMdk4lzIMeta?.name ?? "test-edit",
    path: edit5RfMdk4lzIMeta?.path ?? "/test/edit",
    meta: edit5RfMdk4lzIMeta || {},
    alias: edit5RfMdk4lzIMeta?.alias || [],
    redirect: edit5RfMdk4lzIMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/edit.vue").then(m => m.default || m)
  },
  {
    name: iframegotKGz2n1EMeta?.name ?? "test-iframe",
    path: iframegotKGz2n1EMeta?.path ?? "/test/iframe",
    meta: iframegotKGz2n1EMeta || {},
    alias: iframegotKGz2n1EMeta?.alias || [],
    redirect: iframegotKGz2n1EMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/iframe.vue").then(m => m.default || m)
  },
  {
    name: image_45picker5zbZnca900Meta?.name ?? "test-image-picker",
    path: image_45picker5zbZnca900Meta?.path ?? "/test/image-picker",
    meta: image_45picker5zbZnca900Meta || {},
    alias: image_45picker5zbZnca900Meta?.alias || [],
    redirect: image_45picker5zbZnca900Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/image-picker.vue").then(m => m.default || m)
  },
  {
    name: indexlfCWEqiMbnMeta?.name ?? "test",
    path: indexlfCWEqiMbnMeta?.path ?? "/test",
    meta: indexlfCWEqiMbnMeta || {},
    alias: indexlfCWEqiMbnMeta?.alias || [],
    redirect: indexlfCWEqiMbnMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: orientationluWY2gOUw0Meta?.name ?? "test-orientation",
    path: orientationluWY2gOUw0Meta?.path ?? "/test/orientation",
    meta: orientationluWY2gOUw0Meta || {},
    alias: orientationluWY2gOUw0Meta?.alias || [],
    redirect: orientationluWY2gOUw0Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/orientation.vue").then(m => m.default || m)
  },
  {
    name: photo_select_01O1BprKbkopMeta?.name ?? "test-photo_select_01",
    path: photo_select_01O1BprKbkopMeta?.path ?? "/test/photo_select_01",
    meta: photo_select_01O1BprKbkopMeta || {},
    alias: photo_select_01O1BprKbkopMeta?.alias || [],
    redirect: photo_select_01O1BprKbkopMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/photo_select_01.vue").then(m => m.default || m)
  },
  {
    name: photo_select_02cWDjblVPhVMeta?.name ?? "test-photo_select_02",
    path: photo_select_02cWDjblVPhVMeta?.path ?? "/test/photo_select_02",
    meta: photo_select_02cWDjblVPhVMeta || {},
    alias: photo_select_02cWDjblVPhVMeta?.alias || [],
    redirect: photo_select_02cWDjblVPhVMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/photo_select_02.vue").then(m => m.default || m)
  },
  {
    name: photoATXQxYzO5wMeta?.name ?? "test-photo",
    path: photoATXQxYzO5wMeta?.path ?? "/test/photo",
    meta: photoATXQxYzO5wMeta || {},
    alias: photoATXQxYzO5wMeta?.alias || [],
    redirect: photoATXQxYzO5wMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/photo.vue").then(m => m.default || m)
  },
  {
    name: photo2icfKOkL0NlMeta?.name ?? "test-photo2",
    path: photo2icfKOkL0NlMeta?.path ?? "/test/photo2",
    meta: photo2icfKOkL0NlMeta || {},
    alias: photo2icfKOkL0NlMeta?.alias || [],
    redirect: photo2icfKOkL0NlMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/photo2.vue").then(m => m.default || m)
  },
  {
    name: textJSTTnxDsdvMeta?.name ?? "test-text",
    path: textJSTTnxDsdvMeta?.path ?? "/test/text",
    meta: textJSTTnxDsdvMeta || {},
    alias: textJSTTnxDsdvMeta?.alias || [],
    redirect: textJSTTnxDsdvMeta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/text.vue").then(m => m.default || m)
  },
  {
    name: tossN3G7o8AEY2Meta?.name ?? "test-toss",
    path: tossN3G7o8AEY2Meta?.path ?? "/test/toss",
    meta: tossN3G7o8AEY2Meta || {},
    alias: tossN3G7o8AEY2Meta?.alias || [],
    redirect: tossN3G7o8AEY2Meta?.redirect || undefined,
    component: () => import("C:/workspace/dyb-window-nuxt/pages/test/toss.vue").then(m => m.default || m)
  }
]