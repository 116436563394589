<script lang="ts" setup>
import { useAppStore } from '~/stores/app'
import { logoutAndCleanData } from '~/common/Common'

const { gtag } = useGtag()
const app = useAppStore()

const reload = () => {
  location.reload(true)
}
const moveToMain = () => {
  const router = useRouter()
  router.replace('/')
}
const logout = () => {
  logoutAndCleanData()
}
// eslint-disable-next-line no-undef
onMounted(() => {
  gtag('event', 'open_error_page', {
    um_idx: app.userInfo?.UM_IDX,
    date: new Date().toISOString(),
  })
  // throw new Error('error')
})
</script>
<template>
  <div class="main">
    <img
      alt="Nuxt Logo"
      class="img"
      src="~/assets/images/common/img_error.svg"
    />
    <div>
      <h1>화면을 불러오지 못했어요</h1>
    </div>
    <div class="desc">
      이용에 불편을 드려 죄송합니다.<br />
      아래 버튼을 눌러 다시 로그인또는 메인화면으로 이동 해 주세요.
    </div>
    <div class="buttonWrapper">
      <button class="button" @click="logout">로그인 다시 시도하기</button>
      <button class="button" @click="moveToMain">메인으로 돌아가기</button>
    </div>
  </div>
</template>
<style lang="css" scoped>
div {
  --error-width: 491px;
}

@media (max-width: 760px) {
  div {
    --error-width: 491px;
  }
}

@media (max-width: 550px) {
  div {
    --error-width: 390px;
  }
}

@media (max-width: 450px) {
  div {
    --error-width: 350px;
  }
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8f9;
  height: 100vh;

  flex-direction: column;
  font:
    normal 1rem / 1.5 Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    sans-serif;
  font-weight: 400;
  color: var(--defaultColor);
  letter-spacing: -0.24px;
  gap: 16px;
}

.main img {
  padding-top: 10px;
  width: 105px;
  max-width: 87px;
}

.main h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  color: #191919;
}

.main .desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #72787f;
  text-align: center;
}

.main .solve {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ffedee;
  border-radius: 8px;
  width: var(--error-width);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.main .solve .title {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #454c53;
  background: #ebebed;
  border: 1px solid #ebebed;
  border-radius: 8px;
  width: fit-content;
  padding: 2px 6px;
}

.main .solve p {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #e9333e;
  text-align: center;
}

.main .info {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #72787f;
  text-align: center;
}

.main .buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.main .button {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -2%;
  background: #ebebed;
  border: 1px solid #ebebed;
  border-radius: 8px;
  padding: 12px 20px 12px 20px;

  color: #454c53;
}
</style>
