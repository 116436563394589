/**
 * 1 android M app
 * 2 ios M app
 * 3 android T app
 * 4 ios T app
 * 5 M web
 * 6 T web
 * */
export const enum DEVICE_TYPE {
  ANDROID_M_APP = 1,
  IOS_M_APP = 2,
  ANDROID_T_APP = 3,
  IOS_T_APP = 4,
  M_WEB = 5,
  T_WEB = 6,
}

/*
 * 1 android M app
 * 2 ios M app
 * 3 android T app
 * 4 ios T app
 * 5 M web
 * 6 T web
 * */
export const enum APP_TYPE {
  ANDROID_M_APP = '1',
  IOS_M_APP = '2',
  ANDROID_T_APP = '3',
  IOS_T_APP = '4',
  M_WEB = '5',
  T_WEB = '6',
  WEB = '7',
}

export const enum SITE_TYPE {
  ALL = 'T',
  PC = 'P',
  MOBILE = 'M',
}

export const enum UM_TYPE {
  STUDENT = 'S',
  PARENT = 'P',
}

export const enum BRAND_CD {
  WINDOW = '001',
  MATA = '002',
  LANPUS = '003',
  CREO = '004',
  LIVE_CHOISUN = '006',
  MATE = '008',
  MATE_CREO = '009',
}
export const enum WindowMainType {
  WINDOW = '001',
  MATA = '002',
}

// 윈도우이용여부 Y전체사용 N미사용 S부분사용
export const enum WindowUseType {
  YES = 'Y',
  NO = 'N',
  PARTIAL = 'S',
}
export const enum WindowCiLogo {
  WINDOW = '/assets/images/common/logo_header_dyb.svg',
  MATA = '/assets/images/common/logo_header_mata.svg',
  CREO = '/assets/images/common/logo_header_creo.svg',
  LANPUS = '/assets/images/common/logo_header_lanpus.svg',
}
